import {css} from '@emotion/css';

export const volumeClassName = css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-right: 81px;

    @media (min-width: 360px) and (max-width: 512px) {
        margin-right: 0;
    }
    
    .range {
        position: absolute;
        display: flex;
        align-items: center;
        left: 100%;
        width: 80px;
        height: 2px;
        margin-left: 5px;

        @media (min-width: 360px) and (max-width: 512px) {
            display: none;
        }
    }
    .range-background,
    .range-current,
    .range-seek {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50px;
    }
    .range-current {
        position: relative;
        display: flex;
        align-items: center;
        padding-left: 10px;
    }
    .range-current-thumb {
        position: absolute;
        right: 0;
        width: 10px;
        height: 10px;
        border-radius: 50px;
        transition: transform 200ms ease-out;

        @media (min-width: 360px) and (max-width: 512px) {
            display: none;
        }
    }
    .range-seek {
        cursor: pointer;
        opacity: 0;
        padding-left: 10px;
    }
`;

