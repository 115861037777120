import {buttonClassName} from './styles';
import {Component} from './types';
const Button: Component = ({
    label,
    isDisabled,
    onClick,
    colors,
    children,
}) => {
    return (
        <button
            className={buttonClassName}
            style={{color: colors?.text}}
            disabled={isDisabled}
            data-label={label}
            onClick={onClick}
        >
            {children}
        </button>
    );
};

export default Button;
