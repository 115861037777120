import {memo} from 'react';

import Button from '../button';

import {Component} from './types';

import {ReactComponent as PauseIcon} from '@/components/ui-kit/icons/pause.svg';
import {ReactComponent as PlayIcon} from '@/components/ui-kit/icons/play.svg';

const PlaybackButton: Component = ({isPlaying, onToggle, colors}) => (
    <Button
        label={isPlaying ? 'Pause' : 'Play'}
        onClick={onToggle}
        colors={colors}
    >
        {isPlaying ? <PauseIcon /> : <PlayIcon />}
    </Button>
);

export default memo(PlaybackButton);
