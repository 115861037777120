import {EVideoParams} from '@/components/match-profile-page/components/custom-player/components/time-control/types';

export const getUrlParamValue = (param: string, url: string) => {
    const re = new RegExp(param + '=([0-9]+)');
    const paramValueStr = url.match(re);
    if (paramValueStr) {
        return paramValueStr.toString().split(',')[1];
    }
};

export const updateSeconds = (
    param: string,
    url: string,
    seconds?: number,
) => {
    const currentSeconds = getUrlParamValue(param, url);
    if (currentSeconds && seconds) {
        return url.replace(
            param + '=' + currentSeconds,
            `${
                param
            }=${
                param == EVideoParams.Start ? +currentSeconds - seconds : +currentSeconds + seconds
            }`,
        ).toString();

    }
};