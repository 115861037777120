import {css} from '@emotion/css';

export const actionsClassName = css`
    position: absolute;
    display: flex;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .volume-container {
        position: absolute;
        display: flex;
        top: 0;
        bottom: 75%;
        left: 0;
        right: 0;
        background-image: linear-gradient(rgba(0, 0, 0, 0.7), transparent);
        transition: opacity 300ms ease-out;
        z-index: 15;
    }
    .volume {
        display: flex;
        margin: auto;
        gap: 1rem;
        width: clamp(12rem, 25%, 25rem);
        height: auto;
        transition: all 300ms ease-out;
    }
    .volume-icon {
        position: relative;
        width: 40%;
        height: auto;
    }
    .volume-icon svg {
        width: 100%;
        height: 100%;
    }
    .volume-range {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        height: clamp(5rem, 10vw, 10rem);
        margin: auto;
    }
    .volume-range-background {
        position: absolute;
        width: 100%;
        height: 10%;
        border-radius: 50px;
        background-color: #858585;
    }
    .volume-range-current {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        height: 10%;
        border-radius: 50px;
        background-color: #fff;
        transition: width 200ms ease-out;
    }

    .progress-container {
        position: absolute;
        display: flex;
        top: 0;
        bottom: 0;
        justify-content: center;
        align-items: center;
        opacity: 0;
        pointer-events: none;
        z-index: 20;
    }
    .progress-container.rewind {
        left: 0;
        right: 50%;
        background-image: linear-gradient(to left, transparent, rgba(0, 0, 0, 0.5));
    }
    .progress-container.skip {
        left: 50%;
        right: 0;
        background-image: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.5));
    }
    .progress-container svg {
        width: clamp(5rem, 20%, 10rem);
        height: 100%;
        margin: auto;
    }
    .progress {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
`;
