import {PlayerEntityStates, PlayerEntityTypes} from '@vsporte/media-player';
import {useState, useCallback, useEffect, useRef, useMemo} from 'react';

import DownloadButton from './components/download-button';
import Dropdown from './components/dropdown';
import EpisodeTimeControl from './components/episode-time-control';
import Error from './components/error';
import FullscreenButton from './components/fullscreen-button';
import KeyAction from './components/key-action';
import {KeyActionHandle} from './components/key-action/types';
import LiveBadge from './components/live-badge';
import Loader from './components/loader';
import PlaybackButton from './components/playback-button';
import Progress from './components/progress';
import SettingsButton from './components/settings-button';
import Time from './components/time';
import TimeControl from './components/time-control';
import Volume from './components/volume';
import {SECONDS_DEVIATION, FRACTION_COUNT} from './consts';
import {useLocalStorage} from './hooks/storage-hook';
import {useTimeout} from './hooks/timer-hook';
import {playerClassName, playerControlsClassName, Controls} from './styles';
import {Component} from './types';
import {updateSeconds} from './utils';
import {formatTime} from './utils/format-time';

import {
    EVideoParams,
} from '@/components/match-profile-page/components/custom-player/components/time-control/types';
import {isDefined} from '@/lib/utils/type-guards';

const CustomPlayer: Component = ({
    src,
    autoPlay = true,
    playbackState,
    setPlaybackState,
    currentTime,
    setCurrentTime,
    entityState,
    type,
    colors,
    startTimeOfCurrentTag,
    withTimeChange,
    withDownload,
}) => {
    const [isLive, setIsLive] = useState(false);

    const [displayControls, setDisplayControls] = useState(true);
    const [displayDropdown, setDisplayDropdown] = useState(false);
    const [displayLoader, setDisplayLoader] = useState(true);

    const [currentProgress, setCurrentProgress] = useState(0);
    const [bufferProgress, setBufferProgress] = useState(0);
    const [seekProgress, setSeekProgress] = useState(0);
    const [seekTooltip, setSeekTooltip] = useState('00:00');
    const [seekTooltipPosition, setSeekTooltipPosition] = useState('');

    const [videoDuration, setVideoDuration] = useState(0);

    const [volumeState, setVolumeState] = useLocalStorage('video-volume', 1);
    const [pipState, setPipState] = useState(false);
    const [fullscreenState, setFullscreenState] = useState(false);

    const [playbackRates] = useState([0.5, 0.75, 1, 1.25, 1.5]);
    const [activePlaybackRate, setActivePlaybackRate] = useLocalStorage(
        'video-playback-rate',
        1,
    );

    const videoRef = useRef<HTMLVideoElement>(null);
    const videoContainerRef = useRef<HTMLDivElement>(null);
    const videoKeyActionRef = useRef<KeyActionHandle>(null);
    const [videoError, setVideoError] = useState<MediaError | null>(null);

    const playPromise = useRef<Promise<void>>();
    const volumeData = useRef(volumeState || 1);
    const progressSeekData = useRef(0);

    const [urlsWithChangedTime, setUrlsWithChangedTime] = useState<string[]>([]);

    const [setControlsTimeout] = useTimeout();
    const [setKeyActionVolumeTimeout] = useTimeout();
    const [setLoaderTimeout, clearLoaderTimeout] = useTimeout();

    const checkIsLiveHandler = () => {
        const video = videoRef.current;
        if (entityState !== PlayerEntityStates.Active || !video) {
            return;
        }

        const isCurrentLive = video.currentTime >= video.duration - SECONDS_DEVIATION;
        if (isCurrentLive !== isLive) {
            setIsLive(isCurrentLive);
        }
    };

    const hideControlsHandler = useCallback(() => {
        const video = videoRef.current;

        if (video?.paused) {
            return;
        }

        setDisplayControls(false);
    }, []);

    const showControlsHandler = useCallback(() => {
        const video = videoRef.current;

        setDisplayControls(true);

        if (video?.paused) {
            return;
        }

        setControlsTimeout(() => {
            hideControlsHandler();
        }, 2000);
    }, [hideControlsHandler, setControlsTimeout]);

    const togglePlayHandler = useCallback(() => {
        const video = videoRef.current;

        if (video?.paused || video?.ended) {
            setPlaybackState(true);
            playPromise.current = video.play();
            return;
        }

        if (!playPromise.current) {
            setPlaybackState(false);
            video?.pause();
            return;
        }

        playPromise.current.then(() => {
            setPlaybackState(false);
            video?.pause();
        });
    }, []);

    const videoPlayHandler = useCallback(() => {
        checkIsLiveHandler();
        setPlaybackState(true);
        showControlsHandler();
    }, [showControlsHandler]);

    const videoPauseHandler = useCallback(() => {
        setPlaybackState(false);
        showControlsHandler();
    }, [showControlsHandler]);

    const showLoaderHandler = useCallback(() => {
        setLoaderTimeout(() => setDisplayLoader(true), 300);
    }, [setLoaderTimeout]);

    const hideLoaderHandler = useCallback(() => {
        clearLoaderTimeout();
        setDisplayLoader(false);
    }, [clearLoaderTimeout]);

    const volumeInputHandler = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const video = videoRef.current!;

            video.volume = +event.target.value;
        },
        [],
    );

    const volumeChangeHandler = useCallback(() => {
        const video = videoRef.current!;

        setVolumeState(video.volume);

        if (video.volume === 0) {
            video.muted = true;
        } else {
            video.muted = false;
            volumeData.current = video.volume;
        }
    }, [setVolumeState]);

    const toggleMuteHandler = useCallback(() => {
        const video = videoRef.current!;

        if (video.volume !== 0) {
            volumeData.current = video.volume;
            video.volume = 0;
            setVolumeState(0);
        } else {
            video.volume = volumeData.current;
            setVolumeState(volumeData.current);
        }
    }, [setVolumeState]);

    const getDuration = () => {
        const video = videoRef.current!;
        return formatTime(video?.duration || 0);
    };

    const timeChangeHandler = useCallback(() => {
        const video = videoRef.current!;

        const duration = video.duration || 0;
        const currentTime = video.currentTime || 0;
        const buffer = video.buffered;

        setCurrentProgress((currentTime / duration) * 100);
        setSeekProgress(currentTime);

        if (duration > 0) {
            for (let i = 0; i < buffer.length; i++) {
                if (
                    buffer.start(buffer.length - 1 - i) === 0 ||
                    buffer.start(buffer.length - 1 - i) < video.currentTime
                ) {
                    setBufferProgress(
                        (buffer.end(buffer.length - 1 - i) / duration) * 100,
                    );
                    break;
                }
            }
        }

        const formattedCurrentTime = formatTime(Math.round(currentTime));
        setCurrentTime(formattedCurrentTime);
    }, []);

    const seekMouseMoveHandler = useCallback((event: React.MouseEvent) => {
        const video = videoRef.current!;

        const rect = event.currentTarget.getBoundingClientRect();
        const skipTo = (event.nativeEvent.offsetX / rect.width) * video.duration;

        progressSeekData.current = skipTo;

        let formattedTime: string;

        if (skipTo > video.duration) {
            formattedTime = formatTime(video.duration);
        } else if (skipTo < 0) {
            formattedTime = '00:00';
        } else {
            formattedTime = formatTime(skipTo);
            setSeekTooltipPosition(`${event.nativeEvent.offsetX}px`);
        }

        setSeekTooltip(formattedTime);
    }, []);

    const seekInputHandler = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const video = videoRef.current!;

            const skipTo = progressSeekData.current || +event.target.value;

            video.currentTime = skipTo;
            setCurrentProgress((skipTo / video.duration) * 100);
            setSeekProgress(skipTo);
        },
        [],
    );

    const seekToLiveHandler = () => {
        const video = videoRef.current!;
        if (!video || isLive) {
            return;
        }
        video.currentTime = FRACTION_COUNT;
        setCurrentProgress((FRACTION_COUNT / video.duration) * 100);
        setSeekProgress(FRACTION_COUNT);
    };

    const rewindHandler = useCallback((seconds?: number) => {
        const video = videoRef.current!;

        if (seconds) {
            video.currentTime -= seconds;

            const rewindContainer = videoKeyActionRef.current?.rewind;
            rewindContainer?.animate(
                [{opacity: 0}, {opacity: 1}, {opacity: 1}, {opacity: 0}],
                {
                    duration: seconds * 100,
                    easing: 'ease-out',
                    fill: 'forwards',
                },
            );
        }
    }, []);

    const skipHandler = useCallback((seconds?: number) => {
        const video = videoRef.current!;

        if (seconds) {
            video.currentTime += seconds;

            const forwardContainer = videoKeyActionRef.current?.skip;
            forwardContainer?.animate(
                [{opacity: 0}, {opacity: 1}, {opacity: 1}, {opacity: 0}],
                {
                    duration: seconds * 100,
                    easing: 'ease-out',
                    fill: 'forwards',
                },
            );
        }
    }, []);

    const updateTime = (param: string, seconds?: number) => {
        const currentPlayingUrl =
            urlsWithChangedTime.find(url => (
                url.split('?')[0] == src?.split('?')[0]
            ));
        const urlsWithChangedTimeWithoutActive =
            urlsWithChangedTime.filter(url => (
                url.split('?')[0] == src?.split('?')[0]
            ));
        if (currentPlayingUrl) {
            setUrlsWithChangedTime([
                ...urlsWithChangedTimeWithoutActive,
                updateSeconds(param, currentPlayingUrl, seconds) || '',
            ]);
        }
    };

    const skipTimeHandler = (seconds?: number) => {
        updateTime(EVideoParams.End, seconds);
    };

    const rewindTimeHandler = (seconds?: number) => {
        updateTime(EVideoParams.Start, seconds);
    };

    const togglePipHandler = useCallback(() => {
        if (document.pictureInPictureElement) {
            document.exitPictureInPicture();
        } else {
            videoRef.current!.requestPictureInPicture();
        }
    }, []);

    const pipEnterHandler = useCallback(() => {
        setPipState(true);
    }, []);

    const pipExitHandler = useCallback(() => {
        setPipState(false);
    }, []);

    const toggleFullscreenHandler = useCallback(() => {
        if (document.fullscreenElement) {
            document.exitFullscreen();
        } else {
            videoContainerRef.current?.requestFullscreen();
        }
    }, []);

    const fullscreenChangeHandler = useCallback(() => {
        if (document.fullscreenElement) {
            setFullscreenState(true);
        } else {
            setFullscreenState(false);
        }
    }, []);

    const downloadHandler = useCallback(() => {
        if (src) {
            const link = document.createElement('a');
            document.body.appendChild(link);
            link.href = src;
            link.target = '_blank';
            link.download = '';
            link.click();
            link.remove();
        }
    }, []);

    const toggleDropdownHandler = useCallback(() => {
        setDisplayDropdown((prev) => !prev);
    }, []);

    const changePlaybackRateHandler = useCallback(
        (playbackRate: number) => {
            const video = videoRef.current!;

            video.playbackRate = playbackRate;
            setActivePlaybackRate(playbackRate);
        },
        [setActivePlaybackRate],
    );

    const keyEventHandler = useCallback(
        (event: KeyboardEvent) => {
            const video = videoRef.current!;
            const activeElement = document.activeElement;

            if (
                !activeElement ||
                (activeElement.localName === 'input' &&
                    (activeElement as HTMLInputElement).type !== 'range') ||
                activeElement.localName === 'textarea'
            ) {
                return;
            }

            const {key} = event;

            switch (key) {
                case 'ArrowLeft':
                    event.preventDefault();
                    showControlsHandler();
                    rewindHandler(10);
                    break;
                case 'ArrowRight':
                    event.preventDefault();
                    showControlsHandler();
                    skipHandler(10);
                    break;
                case 'ArrowUp':
                    event.preventDefault();
                    showControlsHandler();
                    if (video.volume + 0.05 > 1) {
                        video.volume = 1;
                    } else {
                        video.volume = +(video.volume + 0.05).toFixed(2);
                    }
                    break;
                case 'ArrowDown':
                    event.preventDefault();
                    showControlsHandler();
                    if (video.volume - 0.05 < 0) {
                        video.volume = 0;
                    } else {
                        video.volume = +(video.volume - 0.05).toFixed(2);
                    }
                    break;
                case ' ':
                    event.preventDefault();
                    togglePlayHandler();
                    break;
            }
        },
        [togglePlayHandler, setKeyActionVolumeTimeout],
    );

    const videoLoadedHandler = useCallback(() => {
        const video = videoRef.current!;

        video.volume = volumeState;
        video.playbackRate = activePlaybackRate;

        setVideoDuration(video.duration);
        timeChangeHandler();

        video.addEventListener('enterpictureinpicture', pipEnterHandler);
        video.addEventListener('leavepictureinpicture', pipExitHandler);
        document.addEventListener('keydown', keyEventHandler);
        document.addEventListener('fullscreenchange', fullscreenChangeHandler);
    }, [
        autoPlay,
        volumeState,
        activePlaybackRate,
        timeChangeHandler,
        pipEnterHandler,
        pipExitHandler,
        keyEventHandler,
        fullscreenChangeHandler,
    ]);

    const errorHandler = useCallback(() => {
        const video = videoRef.current!;

        video.error && setVideoError(video.error);
    }, []);

    const getUrl = () => {
        if (!withTimeChange) {
            return src;
        }
        const currentPlayingUrl =
            urlsWithChangedTime.find(url => (
                url.split('?')[0] == src?.split('?')[0]
            ));
        return currentPlayingUrl || src;
    };

    useEffect(() => {
        return () => {
            document.removeEventListener('fullscreenchange', fullscreenChangeHandler);
            document.removeEventListener('keydown', keyEventHandler);
        };
    }, [fullscreenChangeHandler, keyEventHandler]);

    useEffect(() => {
        if (isDefined(startTimeOfCurrentTag) && !Number.isNaN(startTimeOfCurrentTag)) {
            const video = videoRef.current;

            if (video) {
                video.currentTime = startTimeOfCurrentTag || 0;
            }

            if (video?.paused || video?.ended) {
                setPlaybackState(true);
                playPromise.current = video.play();
                return;
            }
        }
    }, [startTimeOfCurrentTag]);

    const liveBadge = useMemo(() => {
        if (
            type === PlayerEntityTypes.Broadcast &&
            entityState === PlayerEntityStates.Active
        ) {
            return <LiveBadge isLive={isLive} onClick={seekToLiveHandler} />;
        }
    }, [entityState, type, isLive]);

    return (
        <div
            className={playerClassName}
            ref={videoContainerRef}
            style={{
                cursor: displayControls ? 'default' : 'none',
                color: colors.text,
            }}
            onMouseMove={showControlsHandler}
            onMouseLeave={hideControlsHandler}
        >
            <video
                ref={videoRef}
                src={getUrl()}
                controls={false}
                onLoadedMetadata={videoLoadedHandler}
                onClick={togglePlayHandler}
                onPlay={videoPlayHandler}
                onPause={videoPauseHandler}
                onVolumeChange={volumeChangeHandler}
                onTimeUpdate={timeChangeHandler}
                onDoubleClick={toggleFullscreenHandler}
                onSeeking={showLoaderHandler}
                onSeeked={hideLoaderHandler}
                onWaiting={showLoaderHandler}
                onCanPlay={hideLoaderHandler}
                onError={errorHandler}
                muted={true}
                autoPlay={true}
                playsInline={true}
            />
            <Loader on={displayLoader}/>
            <KeyAction />
            <Error error={videoError}/>
            <div className={`${playerControlsClassName}${!displayControls ? ' hide' : ''}`}>
                <Dropdown
                    on={displayDropdown}
                    playbackRates={playbackRates}
                    activePlaybackRate={activePlaybackRate}
                    onClose={setDisplayDropdown}
                    onChangePlaybackRate={changePlaybackRateHandler}
                    isPipMode={pipState}
                    onTogglePip={togglePipHandler}
                />
                <div className="header">
                    <Progress
                        bufferProgress={bufferProgress}
                        currentProgress={currentProgress}
                        videoDuration={videoDuration}
                        seekProgress={seekProgress}
                        seekTooltip={seekTooltip}
                        seekTooltipPosition={seekTooltipPosition}
                        onHover={seekMouseMoveHandler}
                        onSeek={seekInputHandler}
                        colors={colors}
                    />
                </div>
                <div className="body">
                    <div>
                        <PlaybackButton
                            colors={colors}
                            isPlaying={playbackState}
                            onToggle={togglePlayHandler}
                        />
                        <div className="time">
                            <Time time={currentTime}/> / <Time time={getDuration()}/>
                        </div>
                        <TimeControl
                            skipHandler={skipHandler}
                            rewindHandler={rewindHandler}
                        />
                        {isLive && liveBadge}
                    </div>
                    <Controls withDownload={withDownload}>
                        {withTimeChange && (
                            <EpisodeTimeControl
                                skipHandler={skipTimeHandler}
                                rewindHandler={rewindTimeHandler}
                            />
                        )}
                        <Volume
                            colors={colors}
                            volume={volumeState}
                            onToggle={toggleMuteHandler}
                            onSeek={volumeInputHandler}
                        />
                        {withDownload && (
                            <DownloadButton
                                colors={colors}
                                onDownload={downloadHandler}
                            ></DownloadButton>
                        )}
                        <SettingsButton
                            colors={colors}
                            onToggle={toggleDropdownHandler}
                        />
                        <FullscreenButton
                            colors={colors}
                            isFullscreen={fullscreenState}
                            onToggle={toggleFullscreenHandler}
                        />
                    </Controls>
                </div>
            </div>
        </div>
    );
};

export default CustomPlayer;
