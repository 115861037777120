import {memo, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';

import MomentsSelector from './components/moments-selector';
import PlayersSelector from './components/players-selector';
import {BOTH_TEAMS_KEY, MOMENTS_TRANSLATION_KEY} from './consts';
import {Wrapper, TitlesRow, SelectorsRow, RowItem, TitleWrapper, Title, Name, Logo} from './styles';
import {Component, SelectedTeam} from './types';
import {isHost, isGuest} from './utils';

const MomentsSelectors: Component = ({
    match,
    hostSelectedPlayers,
    setHostSelectedPlayers,
    guestSelectedPlayers,
    setGuestSelectedPlayers,
    hostSelectedMoments,
    guestSelectedMoments,
    setMomentsPlayers,
}) => {
    const {t} = useTranslation();

    const [selectedTeam, setSelectedTeam] = useState<SelectedTeam>('host');

    useEffect(() => {
        if (setMomentsPlayers) {
            setMomentsPlayers([...hostSelectedPlayers, ...guestSelectedPlayers]);
        }
    }, [hostSelectedPlayers, guestSelectedPlayers]);

    const hostMomentsSelector = useMemo(() => {
        return (
            <MomentsSelector
                matchId={match?.id}
                name={`${match?.hostTeamId}`}
                momentsPlayers={hostSelectedPlayers}
            />
        );
    }, [hostSelectedPlayers]);

    const hostPlayersSelector = useMemo(() => {
        return (
            <PlayersSelector
                matchId={match?.id}
                teamId={match?.hostTeamId}
                momentsFilters={hostSelectedMoments}
                momentsPlayers={hostSelectedPlayers}
                setPlayers={setHostSelectedPlayers}
            />
        );
    }, [hostSelectedMoments]);

    const guestMomentsSelector = useMemo(() => {
        return (
            <MomentsSelector
                matchId={match?.id}
                name={`${match?.guestTeamId}`}
                momentsPlayers={guestSelectedPlayers}
            />
        );
    }, [guestSelectedPlayers]);

    const guestPlayersSelector = useMemo(() => {
        return (
            <PlayersSelector
                matchId={match?.id}
                teamId={match?.guestTeamId}
                momentsFilters={guestSelectedMoments}
                momentsPlayers={guestSelectedPlayers}
                setPlayers={setGuestSelectedPlayers}
            />
        );
    }, [guestSelectedMoments]);

    return (
        <Wrapper>
            <TitlesRow>
                <TitleWrapper>
                    {match?.hostLogo && <Logo src={match?.hostLogo} />}
                    <Name
                        isSelected={isHost(selectedTeam)}
                        onClick={() => setSelectedTeam('host')}
                    >
                        {match?.hostName}
                    </Name>
                </TitleWrapper>
                <TitleWrapper>
                    {match?.guestLogo && <Logo src={match?.guestLogo} />}
                    <Name
                        isSelected={isGuest(selectedTeam)}
                        onClick={() => setSelectedTeam('guest')}
                    >
                        {match?.guestName}
                    </Name>
                </TitleWrapper>
                <TitleWrapper>
                    <Title
                        isSelected={!isHost(selectedTeam) && !isGuest(selectedTeam)}
                        onClick={() => setSelectedTeam('both')}
                    >
                        {t(`${MOMENTS_TRANSLATION_KEY}.${BOTH_TEAMS_KEY}`)}
                    </Title>
                </TitleWrapper>
            </TitlesRow>
            <SelectorsRow>
                <RowItem isSelected={isHost(selectedTeam)}>
                    {hostMomentsSelector}
                    {hostPlayersSelector}
                </RowItem>
                <RowItem isSelected={isGuest(selectedTeam)}>
                    {guestMomentsSelector}
                    {guestPlayersSelector}
                </RowItem>
                <RowItem isSelected={!isHost(selectedTeam) && !isGuest(selectedTeam)}>
                    <MomentsSelector
                        matchId={match?.id}
                        name={BOTH_TEAMS_KEY}
                        momentsPlayers={[]}
                    />
                </RowItem>
            </SelectorsRow>
        </Wrapper>
    );
};

export default memo(MomentsSelectors);
