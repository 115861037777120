import {memo} from 'react';

import {Colors} from '../../types';

import {progressClassName} from './styles';

interface ProgressProps {
    videoDuration: number;
    bufferProgress: number;
    currentProgress: number;
    seekProgress: number;
    seekTooltipPosition: string;
    seekTooltip: string;
    onHover: (event: React.MouseEvent) => void;
    onSeek: (event: React.ChangeEvent<HTMLInputElement>) => void;
    colors: Colors;
}

const Progress: React.FC<ProgressProps> = ({
    videoDuration,
    bufferProgress,
    currentProgress,
    seekProgress,
    seekTooltipPosition,
    seekTooltip,
    onHover,
    onSeek,
    colors,
}) => {
    return (
        <div className={progressClassName}>
            <div className="range">
                <div
                    className="range-background"
                    style={{backgroundColor: colors.progressBackground}}
                />
                <div
                    className="range-buffer"
                    style={{
                        width: bufferProgress + '%',
                        backgroundColor: colors.progressBuffer,
                    }}
                />
                <div
                    className="range-current"
                    style={{
                        width: currentProgress + '%',
                        backgroundColor: colors.progressCurrent,
                    }}
                >
                    <div
                        className="range-current-thumb"
                        style={{backgroundColor: colors.progressThumb}}
                    />
                </div>
                <input
                    className="range-seek"
                    type="range"
                    step="any"
                    max={videoDuration}
                    value={seekProgress}
                    onMouseMove={onHover}
                    onChange={onSeek}
                />
            </div>

            <span
                className="tooltip"
                style={{left: seekTooltipPosition}}
            >
                {seekTooltip}
            </span>
        </div>
    );
};

export default memo(Progress);
