import {MOMENTS_LENGTH} from './consts';

import {isDefined} from '@/lib/utils/type-guards';

const BASE_SHIFT_SECONDS = -15;

export const prepareTime = (time: number, action: string) => {
    const shiftSeconds = isDefined(MOMENTS_LENGTH[action]?.start)
        ? MOMENTS_LENGTH[action].start
        : BASE_SHIFT_SECONDS;

    return time + shiftSeconds;
};
