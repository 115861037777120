import {memo, useEffect, useMemo, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useParams} from 'react-router-dom';

import MomentsSelectors from '../moments-selectors';
import {BOTH_TEAMS_KEY} from '../moments-selectors/consts';
import Player from '../player';

import {PlayerWrapper} from './styles';
import {Component} from './types';
import {getWatchMomentsFiltersFormHandler} from './utils';

import Loader from '@/components/loader';
import {BroadcastMoment} from '@/lib/enums/broadcast-moment';
import {useBroadcast} from '@/lib/queries/use-broadcast';
import {useMatch} from '@/lib/queries/use-match';
import {RouteType} from '@/lib/types/route-type';

const PlayerBlock: Component = ({
    isMatchProcessingSuccess,
    currentBroadcastIdx,
    startTimeOfCurrentTag,
    momentsPlayers,
    setMomentsPlayers,
    momentsFilters,
    setMomentsFilters,
    playbackState,
    setPlaybackState,
    currentTime,
    setCurrentTime,
}) => {
    const {id} = useParams<RouteType>();
    const {data: match, isLoading: isMatchLoading} = useMatch(Number(id));
    const {data: broadcast, isLoading: isBroadcastLoading} =
        useBroadcast(match?.broadcasts[currentBroadcastIdx]?.broadcastId);

    const [hostSelectedPlayers, setHostSelectedPlayers] = useState<number[]>([]);
    const [guestSelectedPlayers, setGuestSelectedPlayers] = useState<number[]>([]);

    const [hostSelectedMoments, setHostSelectedMoments] = useState<
        Record<string, BroadcastMoment[]>
    >({});
    const [guestSelectedMoments, setGuestSelectedMoments] = useState<
        Record<string, BroadcastMoment[]>
    >({});

    const teamsIds = useMemo(() => {
        return match
            ? [match.hostTeamId.toString(), match.guestTeamId.toString()]
            : [];
        }, [match],
    );

    const defaultFormValues = useMemo(() => {
            return {
                [BOTH_TEAMS_KEY]: [],
                [teamsIds[0]]: [],
                [teamsIds[1]]: [],
            };
        }, [teamsIds],
    );

    const methods = useForm<Record<string, BroadcastMoment[]>>({
        values: momentsFilters,
        defaultValues: defaultFormValues,
    });

    useEffect(() => {
        const subscribe = methods.watch(
            getWatchMomentsFiltersFormHandler({
                getValues: methods.getValues,
                setMomentsFilters,
                setHostSelectedMoments,
                setGuestSelectedMoments,
                setHostSelectedPlayers,
                setGuestSelectedPlayers,
                teamsIds,
            }),
        );

        return subscribe.unsubscribe;
        }, [methods.watch, teamsIds],
    );

    if (isMatchLoading || isBroadcastLoading) {
        return <Loader />;
    }

    return (
        <FormProvider {...methods}>
            {isMatchProcessingSuccess && match && (
                <MomentsSelectors
                    match={match}
                    hostSelectedPlayers={hostSelectedPlayers}
                    guestSelectedPlayers={guestSelectedPlayers}
                    setHostSelectedPlayers={setHostSelectedPlayers}
                    setGuestSelectedPlayers={setGuestSelectedPlayers}
                    hostSelectedMoments={hostSelectedMoments}
                    guestSelectedMoments={guestSelectedMoments}
                    momentsPlayers={momentsPlayers}
                    setMomentsPlayers={setMomentsPlayers}
                />
            )}

            <PlayerWrapper>
                <Player
                    broadcast={broadcast}
                    startTimeOfCurrentTag={startTimeOfCurrentTag}
                    playbackState={playbackState}
                    setPlaybackState={setPlaybackState}
                    currentTime={currentTime}
                    setCurrentTime={setCurrentTime}
                />
            </PlayerWrapper>
        </FormProvider>
    );
};

export default memo(PlayerBlock);
