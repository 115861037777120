import {findIndex} from 'lodash';
import {
    forwardRef,
    memo,
    useEffect,
    useState,
} from 'react';
import {
    Controller,
    ControllerProps,
    FormProvider,
    useForm,
} from 'react-hook-form';

import {defaultFormValues} from './consts';
import {prepareTime} from './moment-card/utils';
import MomentsContent from './moments-content';
import {Form} from './styles';
import {IVideoFormValues, Props} from './types';
import {playMoments, watchVideoFormCallback} from './utils';

import {MOMENTS_LENGTH} from '@/components/match-profile-page/components/moments/moment-card/consts';
import {useInfiniteQueryHook} from '@/lib/hooks/use-infinite-query-hook';
import {useInfiniteMoments} from '@/lib/queries/use-moments';
import {Undefinable} from '@/lib/types';
import {IMoment} from '@/lib/types/dto/moment';
import {NodeJSTimeoutType} from '@/lib/types/timeout';
import {getSeconds} from '@/lib/utils/date-time';

const Moments = forwardRef<NodeJSTimeoutType, Props>(
    (
        {
            matchId,
            playerId,
            broadcastId,
            playbackState,
            currentTime,
            handleTimeLabelClick,
            filter,
            handleClearTimeout,
        },
        ref,
    ) => {
        const [formValues, setFormValues] = useState(defaultFormValues);

        const methods = useForm<IVideoFormValues>({
            defaultValues: formValues,
            values: {...formValues},
        });

        const {watch, control} = methods;

        /** Id текущего воспроизводимого момента */
        const [currentPlayingMoment, setCurrentPlayingMoment] =
            useState<Undefinable<string>>(undefined);

        const [delay, setDelay] =
            useState<Undefinable<number>>(undefined);

        const {
            items: moments,
            isLoading,
            fetchNextPage,
            hasNextPage,
        } = useInfiniteQueryHook(useInfiniteMoments, {matchId, filter, playerId});

        /** Обработчик запуска просмотра моментов для автовоспроизведения */
        const handlePlayMomentCallback = (moment: IMoment) => {
            const preparedStartLabelTime = prepareTime(
                moment?.second,
                moment?.momentName,
            );
            setCurrentPlayingMoment(moment?.recordId);
            handleTimeLabelClick(
                preparedStartLabelTime,
                moment?.half,
            );
        };

        /** Обработчик клика по моменту */
        const handleMomentClick = (moment: IMoment) => {
            const currentMomentIndex = findIndex(
                moments,
                value => value.recordId === moment.recordId,
            );
            playMoments({
                ref,
                moments,
                callback: handlePlayMomentCallback,
                currentIdx: currentMomentIndex,
                handleClearTimeout,
            });
        };

        /** Прослушиваем изменение формы и при заполнении качества отправляем запрос
         * на создание видео на бек */
        useEffect(() => {
            const subscribe = watch(
                watchVideoFormCallback({
                    setFormValues,
                    moments,
                }),
            );

            return subscribe.unsubscribe;
        }, [watch, moments, broadcastId]);

        useEffect(() => {
            if (!playbackState) {
                handleClearTimeout();

                const currentMomentIndex = findIndex(
                    moments,
                    value => value.recordId === currentPlayingMoment,
                );
                const currentMomentSecond = moments[currentMomentIndex]?.second || 0;
                const currentMomentSecondsBeforeStart =
                    MOMENTS_LENGTH[moments[currentMomentIndex]?.momentName]?.start || 0;
                const currentMomentDelay = MOMENTS_LENGTH[moments[currentMomentIndex]?.momentName]?.length || 0;

                setDelay(
                    currentMomentSecond +
                    currentMomentSecondsBeforeStart +
                    currentMomentDelay -
                    getSeconds(currentTime),
                );
            }
        }, [playbackState, currentTime]);

        useEffect(() => {
            if (playbackState) {
                setDelay(undefined);

                const currentMomentIndex = findIndex(
                    moments,
                    value => value.recordId === currentPlayingMoment,
                );
                playMoments({
                    ref,
                    moments,
                    callback: handlePlayMomentCallback,
                    currentIdx: currentMomentIndex,
                    delay,
                    handleClearTimeout,
                });
            }
        }, [playbackState, delay]);

        const renderCheckboxGroup: ControllerProps<
            IVideoFormValues,
            'tags'
        >['render'] = () => {
            return (
                <MomentsContent
                    hasNextPage={!!hasNextPage}
                    fetchNextPage={fetchNextPage}
                    moments={moments}
                    isLoading={isLoading}
                    currentPlayingMoment={currentPlayingMoment}
                    setCurrentPlayingMoment={setCurrentPlayingMoment}
                    handleMomentClick={handleMomentClick}
                />
            );
        };

        return (
            <>
                <FormProvider {...methods}>
                    <Form>
                        <Controller
                            name="tags"
                            control={control}
                            render={renderCheckboxGroup}
                        />
                    </Form>
                </FormProvider>
            </>
        );
    },
);

export default memo(Moments);
