import {css} from '@emotion/css';

export const buttonClassName = css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: transparent;

    svg {
        width: 30px;
        height: 100%;
    }
    
    &.disabled {
        pointer-events: none;
    }

    @media (min-width: 360px) and (max-width: 479px) {
        width: 30px;
        height: 30px;
    }
`;
