import {css} from '@emotion/css';

export const dropdownClassName = css`
    position: absolute;
    bottom: 150%;
    right: 0;
    width: 350px;
    overflow: hidden;
    transition: opacity 200ms ease-out, height 300ms ease-out;
    background-color: rgba(0, 0, 0, 0.8);
    font-size: 14px;

    @media (min-width: 512px) and (max-width: 1199px) {
        font-size: 13px;
        bottom: 132%;
    }

    @media (min-width: 480px) and (max-width: 511px) {
        font-size: 12px;
        bottom: 127%;
    }

    @media (min-width: 360px) and (max-width: 479px) {
        width: 280px;
        font-size: 10px;
        bottom: 120%;
    }
    
    .menu {
        position: absolute;
        bottom: 0;
        width: 100%;
        transition: transform 300ms ease;
    }
    
    .list {
        overflow: auto;
    }
    
    .label,
    .list-item {
        display: flex;
        align-items: center;
        padding: 10px;
        gap: 1rem;
        cursor: pointer;
        transition: background-color 200ms;
        
        .icon {
            width: 19px;
            
            @media (min-width: 512px) and (max-width: 1199px) {
                width: 17px;
            }
            
            @media (min-width: 480px) and (max-width: 511px) {
                width: 15px;
            }
            
            @media (min-width: 360px) and (max-width: 479px) {
                width: 13px;
            }
        }

        @media (min-width: 512px) and (max-width: 1199px) {
            padding: 6px 10px;
        }

        @media (min-width: 480px) and (max-width: 511px) {
            padding: 6px 10px;
        }

        @media (min-width: 360px) and (max-width: 479px) {
            padding: 2px 10px;
        }
    }
    .label:hover,
    .list-item:hover {
        background-color: rgba(51, 51, 51, 0.8);
    }
    .label {
        font-weight: 600;
    }
    .list-item {
        justify-content: space-between;
        text-align: right;
    }
    .list-item.active::after {
        content: '';
        display: flex;
        width: 10px;
        height: 10px;
        border-radius: 50px;
        background-color: #F2F3F5;
    }
`;

