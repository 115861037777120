export const OPTIONS = [
    {
        label: 'секунда',
        value: 1,
    },
    {
        label: 'секунды',
        value: 2,
    },
    {
        label: 'секунды',
        value: 3,
    },
    {
        label: 'секунд',
        value: 5,
    },
    {
        label: 'секунд',
        value: 10,
    },
    {
        label: 'секунд',
        value: 30,
    },
];
