import {css} from '@emotion/css';

export const selectWrapperClassName = css`
    position: relative;
    border-radius: 12px;

    &[data-mode="cells"] .select {
        grid-template-columns: repeat(3, 1fr);
    }

    &[data-mode="cells"] .option {
        justify-content: center;
    }
`;

export const selectClassName = css`
    display: grid;
    position: absolute;
    list-style: none;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    z-index: 10;
    background-color: #F2F3F5;
    top: -170px;
`;

export const placeholderClassName = css`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #F2F3F5 !important;
    background: transparent;
    width: 28px;
    height: 28px;
    border-radius: 2px;

    &[data-selected="true"] {
        color: black;
    }
`;
