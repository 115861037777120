import {
    forwardRef,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import ReactPlayer from 'react-player';
import {OnProgressProps} from 'react-player/base';

import videoNotFound from '../../assets/video-not-found.png';
import {LiveBadge} from '../live-badge';
import NotFound from '../not-found';

import {
    FRACTION_COUNT,
    SECONDS_DEVIATION,
    SHIFT_SECONDS,
    defaultReactPlayerConfig,
} from './consts';
import {ThumbnailWrapper, ThumbnailImage, PlayerWrapper} from './styles';
import {ArrowButtonsKeys, Component, Props} from './types';

import {
    PlayerEntityStates,
    PlayerEntityTypes,
} from '@/constants/player-entity-type';
import {useCombinedRefs} from '@/hooks/use-combined-refs';

const Player: Component = forwardRef<ReactPlayer, Props>(
    (
        {entityState, type, url, preview, onPlay, onProgress, onSeek, ...props},
        ref,
    ) => {
        const innerRef = useRef<ReactPlayer>(null);
        const playerRef = useCombinedRefs<ReactPlayer>(ref, innerRef);

        const [isLive, setIsLive] = useState(false);

        const handleSeekToByPressKey = useCallback(
            (evt: KeyboardEvent) => {
                if (
                    evt.key !== ArrowButtonsKeys.Left &&
                    evt.key !== ArrowButtonsKeys.Right
                ) {
                    return;
                }

                const currentTime = playerRef.current?.getCurrentTime();

                if (!currentTime) {
                    return;
                }

                const seconds =
                    currentTime +
                    (evt.key === ArrowButtonsKeys.Left
                        ? -SHIFT_SECONDS
                        : SHIFT_SECONDS);

                playerRef.current?.seekTo(seconds);
            },
            [playerRef],
        );

        const handleSeekToLive = () => {
            if (!playerRef.current || isLive) {
                return;
            }
            playerRef.current.seekTo(FRACTION_COUNT, 'fraction');
        };

        /** Утилита проверяет, в лайве ли отображается трансляция */
        const handleCheckIsLive = () => {
            if (
                entityState !== PlayerEntityStates.Active ||
                !playerRef.current
            ) {
                return;
            }

            const isCurrentLive =
                playerRef.current.getCurrentTime() >=
                playerRef.current?.getDuration() - SECONDS_DEVIATION;

            if (isCurrentLive !== isLive) {
                setIsLive(isCurrentLive);
            }
        };

        const handlePlay = () => {
            handleCheckIsLive();
            onPlay?.();
        };

        const handleSeek = (seconds: number) => {
            handleCheckIsLive();
            onSeek?.(seconds);
        };

        const handleProgress = (progressProps: OnProgressProps) => {
            handleCheckIsLive();
            onProgress?.(progressProps);
        };

        useEffect(() => {
            document.addEventListener('keydown', handleSeekToByPressKey);
            return () =>
                document.removeEventListener('keydown', handleSeekToByPressKey);
        }, []);

        const liveBadge = useMemo(() => {
            if (
                type === PlayerEntityTypes.Broadcast &&
                entityState === PlayerEntityStates.Active
            ) {
                return <LiveBadge isLive={isLive} onClick={handleSeekToLive} />;
            }
        }, [entityState, type, isLive]);

        const light = (
            <ThumbnailWrapper>
                <ThumbnailImage
                    src={preview || videoNotFound}
                    alt="Thumbnail"
                />
            </ThumbnailWrapper>
        );

        if (
            entityState === PlayerEntityStates.NotFound ||
            (!url && entityState === PlayerEntityStates.Active)
        ) {
            return <NotFound />;
        }

        if (
            (entityState === PlayerEntityStates.NotStarted &&
                type === PlayerEntityTypes.Broadcast) ||
            !url
        ) {
            return light;
        }

        return (
            <PlayerWrapper>
                <ReactPlayer
                    ref={playerRef}
                    controls
                    playing
                    url={url}
                    width="100%"
                    height="100%"
                    config={defaultReactPlayerConfig}
                    onPlay={handlePlay}
                    onSeek={handleSeek}
                    onProgress={handleProgress}
                    {...props}
                    playsInline={true}
                />
                {liveBadge}
            </PlayerWrapper>
        );
    },
);

export default Player;
