import {TriangleDownIcon, TriangleUpIcon} from '@chakra-ui/icons';
import {Box, Image} from '@chakra-ui/react';
import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';
import {memo, MouseEventHandler, useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {MAX_LENGTH_PLAYER_NAME, MOMENTS_TRANSLATION_KEY} from './consts';
import {
    getEvent,
    Player,
    PlayerWrapper,
    TimeWrapper,
    Time,
    Moment,
    CardWrapper,
    Card,
} from './styles';
import {Component} from './types';

import EllipsisText from '@/components/ui-kit/ellipsis-text';
import appTheme from '@/components/ui-kit/theme/app-theme';
import {HALF_LENGTH} from '@/lib/constants/date-time';
import {MatchHalf} from '@/lib/constants/match-statistics';
import {getMMSSTimeFromSeconds} from '@/lib/utils/date-time';

const TimeLabelCard: Component = ({
    moment,
    isPlaying,
    handleTimeLabelPlayButtonClick,
    setPlaying,
}) => {
    const {t} = useTranslation();

    /** Высчитываем, есть ли имена игроков или нет в текущем моменте */
    const hasPlayers = !isEmpty(moment.playerNames);

    const handlePlayButtonClick: MouseEventHandler<HTMLDivElement> =
        useCallback(
            (event) => {
                if (event.target instanceof HTMLInputElement) {
                    return;
                }
                handleTimeLabelPlayButtonClick(moment);
                setPlaying?.(true);
            },
            [handleTimeLabelPlayButtonClick, moment, setPlaying],
        );

    const players = useMemo(() => {
        if (!hasPlayers) {
            return null;
        }
        const playerNames = moment.playerNames?.map((name, idx) => {
            /** Для события замены отрисовываем иконку замены*/
            const subtitutionIcon =
                moment.playerNames &&
                moment.playerNames.length > 1 &&
                (idx === 0 ? (
                    <TriangleUpIcon color={appTheme.colors.green[500]} />
                ) : (
                    <TriangleDownIcon color={appTheme.colors.red[300]} />
                ));
            return (
                <PlayerWrapper key={name}>
                    {subtitutionIcon}
                    <EllipsisText
                        maxLength={MAX_LENGTH_PLAYER_NAME}
                        text={name}
                        as={Player}
                    />
                </PlayerWrapper>
            );
        });
        return <Box>{playerNames}</Box>;
    }, [hasPlayers, moment.playerNames]);

    const momentName = useMemo(() => {
        const labelName =
            t(`${MOMENTS_TRANSLATION_KEY}.${moment.momentName}`) ??
            moment.momentName;
        const text = hasPlayers ? capitalize(labelName) : labelName;
        return (
            <Moment as={getEvent(!hasPlayers)}>
                {text}
            </Moment>
        );
    }, [hasPlayers, moment.momentName, t]);

    return (
        <CardWrapper
            isTechEvent={!hasPlayers && !moment.logoUrl}
            isPlaying={isPlaying}
            onClick={handlePlayButtonClick}
            dataId={moment.recordId}
        >
            <Card>
                <Box>
                    {moment.logoUrl && (
                        <Image src={moment.logoUrl} height="32px" />
                    )}
                </Box>
                <Box>
                    <TimeWrapper isTechEvent={!hasPlayers && !moment.logoUrl}>
                        {moment.second > HALF_LENGTH && (
                            <Time>{t('ui.additionalTime')}</Time>
                        )}
                        {getMMSSTimeFromSeconds(
                            moment.half === MatchHalf.Second
                                ? moment.second + HALF_LENGTH
                                : moment.second,
                        )}
                    </TimeWrapper>
                    {hasPlayers && momentName}
                </Box>
            </Card>
            {players}
            {!hasPlayers && momentName}
        </CardWrapper>
    );
};

export default memo(TimeLabelCard);
