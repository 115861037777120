import {css} from '@emotion/css';

export const loaderClassName = css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: clamp(4rem, 6vw, 6rem);
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 30;

    div {
        width: 48px;
        height: 48px;
        border: 3px solid #F2F3F5;
        border-bottom-color: transparent;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: loading 1s linear infinite;

        @media (min-width: 480px) and (max-width: 511px) {
            width: 30px;
            height: 30px;
        }

        @media (min-width: 360px) and (max-width: 479px) {
            width: 25px;
            height: 25px;
        }
    }

    .enter {
        opacity: 0;
    }
    .enter-active {
        opacity: 1;
        transition: opacity 300ms ease-out;
    }
    .vp-loader-exit {
        opacity: 1;
    }
    .exit-active {
        opacity: 0;
        transition: opacity 300ms ease-out;
    }

    @keyframes loading {
        0% {
            transform: rotate(0);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

