export const COLORS = {
    white: '#FFFFFF',

    turquoise: '#00ADC3',

    lightGray: '#F2F3F5',
    gray: '#D4DCE5',
    darkGray: '#96A0AB',

    darkBlue: '#061E3B',
};
