import {memo, MouseEventHandler, useEffect, useRef} from 'react';

import {Option} from '../../types';

import {optionClassName} from './styles';
import {Component} from './types';

const SelectOption: Component = ({option, onClick}) => {
    const optionRef = useRef<HTMLLIElement>(null);

    const handleClick = (
        clickedValue: Option['value'],
    ): MouseEventHandler<HTMLLIElement> => () => {
        onClick(clickedValue);
    };

    useEffect(() => {
        const option = optionRef.current;
        if (!option) {
            return;
        }
        const handleEnterKeyDown = (event: KeyboardEvent) => {
            if (document.activeElement === option && event.key === 'Enter') {
                onClick(option.value);
            }
        };

        option.addEventListener('keydown', handleEnterKeyDown);
        return () => {
            option.removeEventListener('keydown', handleEnterKeyDown);
        };
    }, [option.value, onClick]);

    return (
        <li
            className={optionClassName}
            value={option.value}
            onClick={handleClick(option.value)}
            tabIndex={0}
            data-testid={`select-option-${option.value}`}
            ref={optionRef}
        >
            {option.value}
        </li>
    );
};

export default memo(SelectOption);
