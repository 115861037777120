import {intersection, difference, uniq} from 'lodash';
import {Dispatch, SetStateAction} from 'react';
import {WatchObserver} from 'react-hook-form';

import {BOTH_TEAMS_KEY} from '../moments-selectors/consts';

import {BroadcastMoment} from '@/lib/enums/broadcast-moment';
import {isDefined} from '@/lib/utils/type-guards';

export type GetWatchMomentsFiltersHandlerProps = {
    getValues: (name: string) => BroadcastMoment[];
    teamsIds: string[];
    setMomentsFilters: Dispatch<
        SetStateAction<Record<string, BroadcastMoment[]>>
    >;
    setHostSelectedMoments: Dispatch<
        SetStateAction<Record<string, BroadcastMoment[]>>
    >;
    setGuestSelectedMoments: Dispatch<
        SetStateAction<Record<string, BroadcastMoment[]>>
    >;
    setHostSelectedPlayers: Dispatch<SetStateAction<number[]>>;
    setGuestSelectedPlayers: Dispatch<SetStateAction<number[]>>;
};

export const getWatchMomentsFiltersFormHandler =
    ({
        getValues,
        teamsIds,
        setMomentsFilters,
        setHostSelectedMoments,
        setGuestSelectedMoments,
        setHostSelectedPlayers,
        setGuestSelectedPlayers,
    }: GetWatchMomentsFiltersHandlerProps): WatchObserver<
        Record<string, BroadcastMoment[]>
    > =>
    (values, info) => {
        /** Получаем пересечение значений селекторов команд */
        const hostTeamId = teamsIds[0];
        const guestTeamId = teamsIds[1];

        const sharedArray = intersection(
            getValues(hostTeamId),
            getValues(guestTeamId),
        );

        /** Получаем текущее измененное значение */
        const changedValue =
            info.name && Array.isArray(values[info.name])
                ? values[info.name]?.filter(isDefined) ?? []
                : [];

        /** Формируем объект с новыми значениями фильтров */
        const newValues = values;

        /** Задаем новое значение селектору обеих команд при изменении значения для каждой команды */
        if (info.name && teamsIds.includes(info.name)) {
            newValues[BOTH_TEAMS_KEY] = sharedArray;
        }

        /** Если меняется значение селектора обеих команд -
         * добавляем или удаляем элементы для селекторов команд */
        if (info.name === BOTH_TEAMS_KEY) {
            /** Получаем разницу элементов массива с пересечением и массива с новыми значениями
             * селектора обеих команд
             */
            const diff = difference(sharedArray, changedValue);

            teamsIds.forEach((teamId) => {
                /** Получает значение для текущей команды */
                const teamValue = values[teamId]?.filter(isDefined) ?? [];
                /** Получаем новое значение: - если длина массива с пересечением меньше длины
                 *  массива с новым значением для обеих команд,
                 * добавляем новые элементы
                 * - если длина массива меньше, то удаляем элементы
                 */
                newValues[teamId] =
                    changedValue?.length > sharedArray.length
                        ? uniq([...teamValue, ...changedValue])
                        : teamValue.filter((val) => !diff.includes(val));
            });
            setHostSelectedPlayers([]);
            setGuestSelectedPlayers([]);
        }
        setHostSelectedMoments({[hostTeamId]: newValues[hostTeamId]} as Record<string, BroadcastMoment[]>);
        setGuestSelectedMoments({[guestTeamId]: newValues[guestTeamId]} as Record<string, BroadcastMoment[]>);
        setMomentsFilters(newValues as Record<string, BroadcastMoment[]>);
    };
