import Hammer from 'hammerjs';
import {FC, PropsWithChildren, useEffect, useRef} from 'react';

const withHammer = (Component: FC<PropsWithChildren>) => (props) => {
    const ref = useRef();

    useEffect(() => {
        const element = ref.current;
        if (element) {
            const hammer = new Hammer(element);

            hammer.on('tap', (event: any) => {
                event.preventDefault();
                const clickEvent = new MouseEvent('click', {
                    bubbles: true,
                    cancelable: true,
                    view: window,
                });
                event.target?.dispatchEvent(clickEvent);
            });

            return () => {
                hammer.destroy();
            };
        }
    }, []);

    return <Component ref={ref} {...props} />;
};

export default withHammer;
