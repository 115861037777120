import {Box} from '@chakra-ui/react';
import {css} from '@emotion/css';
import styled from '@emotion/styled';

export const playerClassName = css`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #000000;

    video {
        width: 100%;
        height: 100%;
        z-index: 1;
    }
`;

export const playerControlsClassName = css`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    left: 0;
    right: 0;
    bottom: 0;
    transition: opacity 300ms ease-out;
    z-index: 15;
    font-size: 17px;
    
    &.hide {
        opacity: 0;
        pointer-events: none;
    }
    
    .header {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0 20px;
            
        @media (min-width: 512px) and (max-width: 1199px) {
            padding: 0 10px;    
        }
            
        @media (min-width: 360px) and (max-width: 511px) {
            padding: 0 5px;
        }
    }
    
    .body {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 7px 16px 8px 16px;
        
        & > div {
            position: relative;
            display: flex;
            align-items: center;
        }
            
        @media (min-width: 512px) and (max-width: 1199px) {
            padding: 7px 7px 7.5px 8px;
        }    
            
        @media (min-width: 480px) and (max-width: 511px) {
            padding: 9px 2px 2px 3px;
        }

        @media (min-width: 360px) and (max-width: 479px) {
            padding: 9px 2px 6.5px 3px;
        }
    }
    
    .time {
        display: flex;
        align-items: center;
            
        @media (min-width: 360px) and (max-width: 1199px) {
            display: none;
        }
    }
`;

export const Controls = styled(Box)<{withDownload: boolean}>`
    gap: 9px;

    @media (min-width: 360px) and (max-width: 1199px) {
        ${(props) =>
            `gap: ${props.withDownload ? '0' : '10px'}`
        };
    }
`;
