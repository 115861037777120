import {useState, memo, useCallback, useRef, useEffect} from 'react';
import {CSSTransition} from 'react-transition-group';

import {dropdownClassName} from './styles';
import {Component, DropdownItem} from './types';

import {ReactComponent as ArrowLeftIcon} from '@/components/ui-kit/icons/arrow-left.svg';
import {ReactComponent as MovieIcon} from '@/components/ui-kit/icons/movie.svg';
import {ReactComponent as PipInIcon} from '@/components/ui-kit/icons/pip-in.svg';
import {ReactComponent as PipOutIcon} from '@/components/ui-kit/icons/pip-out.svg';

const Dropdown: Component = ({
    on,
    playbackRates,
    activePlaybackRate,
    onClose,
    onChangePlaybackRate,
    isPipMode,
    onTogglePip,
}) => {
    const [isMounted, setIsMounted] = useState(false);
    const [isIndex, setIsIndex] = useState(true);
    const [activeType, setActiveType] =
        useState<DropdownItem>('speed');
    const [dropdownHeight, setDropdownHeight] = useState<'initial' | number>(
        'initial',
    );

    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!isMounted) {
            return;
        }

        const outsideClickHandler = (event: MouseEvent) => {
            if (!isMounted || !dropdownRef || !dropdownRef.current) {
                return;
            }
            if (!dropdownRef.current.contains(event.target as Node)) {
                onClose(false);
            }
        };

        document.addEventListener('click', outsideClickHandler);

        return () => {
            document.removeEventListener('click', outsideClickHandler);
        };
    }, [isMounted, onClose]);

    useEffect(() => {
        if (!on) {
            return;
        }

        const dropdown = dropdownRef.current!;
        const dropdownMenu = dropdown.firstChild as HTMLElement;

        setDropdownHeight(dropdownMenu?.offsetHeight || 'initial');
    }, [on]);

    const dropdownEnteredHandler = useCallback(() => {
        setIsMounted(true);
    }, []);

    const dropdownExitedHandler = useCallback(() => {
        setIsMounted(false);
        setIsIndex(true);
        setDropdownHeight('initial');
    }, []);

    const calcHeight = useCallback((element: HTMLElement) => {
        setDropdownHeight(element.offsetHeight);
    }, []);

    const selectMenuHandler = useCallback((type: DropdownItem) => {
        return () => {
            if (type == 'pictureInPicture') {
                onTogglePip();
            } else {
                setIsIndex(false);
                setActiveType(type);
            }
        };
    }, []);

    const selectPlaybackRateHandler = useCallback(
        (playbackRate: number) => {
            return () => {
                setIsIndex(true);
                onChangePlaybackRate(playbackRate);
            };
        },
        [onChangePlaybackRate],
    );

    const indexMenu = (
        <div className="menu">
            <ul className="list">
                <li className="list-item" onClick={selectMenuHandler('speed')}>
                    <MovieIcon/>
                    <span>x {activePlaybackRate}</span>
                    <span>Скорость воспроизведения</span>
                </li>
                <li className="list-item" onClick={selectMenuHandler('pictureInPicture')}>
                    {isPipMode ? <PipOutIcon /> : <PipInIcon />}
                    <span>Картинка в картинке</span>
                </li>
                {/*<li
                  className="list-item"
                  onClick={selectMenuHandler('resolution')}
                >
                  <span>Разрешение</span>
                  <span>1080p</span>
                </li>*/}
            </ul>
        </div>
    );

    const mainMenu = (
        <div className="menu">
            <div className="label" onClick={() => setIsIndex(true)}>
                <ArrowLeftIcon className="icon" />
                <span>
                    {activeType === 'speed' && 'Скорость воспроизведения'}
                    {/*activeType === 'resolution' && 'Разрешение'*/}
                </span>
            </div>
            <ul className="list">
                {activeType === 'speed' &&
                    playbackRates.map((playbackRate) => (
                        <li
                            key={playbackRate}
                            className={`list-item${
                                activePlaybackRate === playbackRate ? ' active' : ''
                            }`}
                            onClick={selectPlaybackRateHandler(playbackRate)}
                        >
                            {playbackRate}
                        </li>
                    ))
                }
                {/*{activeType === 'resolution' &&
                    [540, 720, 1080].map((resolution) => (
                        <li
                            key={resolution}
                            className={`list-item${resolution === 1080 ? ' active' : ''}`}
                            onClick={() => setIsIndex(true)}
                        >
                            {resolution}
                        </li>
                    ))
                }*/}
            </ul>
        </div>
    );

    return (
        <CSSTransition
            in={on}
            classNames={dropdownClassName}
            timeout={200}
            mountOnEnter
            unmountOnExit
            onEntered={dropdownEnteredHandler}
            onExited={dropdownExitedHandler}
        >
            <div
                className={dropdownClassName}
                ref={dropdownRef}
                style={{height: dropdownHeight}}
            >
                <CSSTransition
                    in={isIndex}
                    timeout={300}
                    mountOnEnter
                    unmountOnExit
                    onEnter={calcHeight}
                >
                    {indexMenu}
                </CSSTransition>

                <CSSTransition
                    in={!isIndex}
                    timeout={300}
                    mountOnEnter
                    unmountOnExit
                    onEnter={calcHeight}
                >
                    {mainMenu}
                </CSSTransition>
            </div>
        </CSSTransition>
    );
};

export default memo(Dropdown);
