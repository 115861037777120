import {memo} from 'react';

import {errorClassName} from './styles';

import {ReactComponent as ReloadIcon} from '@/components/ui-kit/icons/reload.svg';

interface ErrorProps {
    error: MediaError | null;
}

const Error: React.FC<ErrorProps> = ({error}) => {
    const refreshHandler = () => {
        window.location.reload();
    };

    return error ? (
        <div className={errorClassName}>
            <p>
                {error.code ? `${error.code}: ` : ''}
                {error.message || 'Ошибка...'}
            </p>
            <div onClick={refreshHandler}>
                <ReloadIcon  />
            </div>
        </div>
    ) : null;
};

export default memo(Error);
