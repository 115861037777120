import {forwardRef, memo, useRef} from 'react';

import {actionsClassName} from './styles';

import {ReactComponent as TrackRewindIcon} from '@/components/ui-kit/icons/track-rewind.svg';
import {ReactComponent as TrackSkipIcon} from '@/components/ui-kit/icons/track-skip.svg';

const KeyAction = forwardRef(() => {
        const rewindRef = useRef<HTMLDivElement>(null);
        const skipRef = useRef<HTMLDivElement>(null);

        return (
            <div className={actionsClassName}>
                <div className="progress-container rewind" ref={rewindRef}>
                    <div className="progress">
                        <TrackRewindIcon/>
                    </div>
                </div>
                <div className="progress-container skip" ref={skipRef}>
                    <div className="progress">
                        <TrackSkipIcon/>
                    </div>
                </div>
            </div>
        );
    },
);

export default memo(KeyAction);
