import {FC} from 'react';

export type Props = {
    currentPlayingTagUrl?: string;
    skipHandler: (seconds?: number) => void;
    rewindHandler: (seconds?: number) => void;
};

export type Component = FC<Props>;

export type TimeSelectItem = {
    label: string;
    value: number;
};

export enum EVideoParams {
    Start = 'start_second',
    End = 'end_second'
}
