import {memo} from 'react';

import Button from '../button';

import {Component} from './types';

import {ReactComponent as DownloadIcon} from '@/components/ui-kit/icons/download.svg';

const DownloadButton: Component = ({onDownload, colors}) => (
    <Button colors={colors} label="Скачать" onClick={onDownload}>
        <DownloadIcon />
    </Button>
);

export default memo(DownloadButton);
