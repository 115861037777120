import {Flex, Box} from '@chakra-ui/react';
import styled from '@emotion/styled';

export const Control = styled(Flex)`
    align-items: center;
    gap: 6px;
    margin: 0 21px 0 13px;
`;

export const Icon = styled(Box)`
    cursor: pointer;
`;
