import {Tooltip} from 'antd';
import {memo, useState} from 'react';

import TimeSelect from './components/time-select';

import {Control, Icon} from './styles';
import {Component, EVideoParams} from './types';
import {getUrlParamValue} from './utils';

import {Option} from '@/components/match-profile-page/components/controls/select/types';
import {ReactComponent as RewindIcon} from '@/components/ui-kit/icons/rewind.svg';
import {ReactComponent as SkipIcon} from '@/components/ui-kit/icons/skip.svg';
import {Undefinable} from '@/lib/types';

const TimeControl: Component = ({
    currentPlayingTagUrl,
    skipHandler,
    rewindHandler,
}) => {
    const [extraTime, setExtraTime] =
        useState<Undefinable<Option>>({value: 5, label: ' секунд'});

    const getTooltipText = (text: string) => {
        return `${extraTime?.value || '0'} ${extraTime?.label} ${text}`;
    };

    const isSkipDisabled = () => {
        if (currentPlayingTagUrl && extraTime) {
            const startSecond = +(getUrlParamValue(EVideoParams.Start, currentPlayingTagUrl) || 0);
            return startSecond <= 10 || startSecond < extraTime.value;
        }
    };

    return (
        <Control>
            <Tooltip
                placement="top"
                title={getTooltipText('назад')}
                overlayInnerStyle={{
                    fontWeight: '500',
                    textAlign: 'center',
                    maxWidth: 'min-content',
                }}
            >
                <Icon
                    onClick={() => rewindHandler(extraTime?.value)}
                    style={{pointerEvents: !isSkipDisabled() ? 'auto' : 'none'}}
                >
                    <RewindIcon />
                </Icon>
            </Tooltip>
            <TimeSelect
                extraTime={extraTime}
                selectTimeHandler={setExtraTime}
            ></TimeSelect>
            <Tooltip
                placement="top"
                title={getTooltipText('вперед')}
                overlayInnerStyle={{
                    fontWeight: '500',
                    textAlign: 'center',
                    maxWidth: 'min-content',
                }}
            >
                <Icon
                    onClick={() => skipHandler(extraTime?.value)}
                    style={{pointerEvents: extraTime ? 'auto' : 'none'}}
                >
                    <SkipIcon />
                </Icon>
            </Tooltip>
        </Control>
    );
};

export default memo(TimeControl);
