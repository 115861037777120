import {PlayerEntityStates, PlayerEntityTypes} from '@vsporte/media-player';

import CustomPlayer from '../custom-player';

import {playerColors} from './consts';
import {
    PlayerWrapper,
} from './styles';
import {Component} from './types';
import {calcBroadcastState} from './utils';

const Player: Component = ({
    broadcast,
    startTimeOfCurrentTag,
    playbackState,
    setPlaybackState,
    currentTime,
    setCurrentTime,
}) => {
    const broadcastState = calcBroadcastState(broadcast);

    return (
        <PlayerWrapper>
            <CustomPlayer
                src={
                    broadcastState === PlayerEntityStates.Active
                        ? broadcast?.clientUrl
                        : broadcast?.records[0]
                }
                playbackState={playbackState}
                setPlaybackState={setPlaybackState}
                currentTime={currentTime}
                setCurrentTime={setCurrentTime}
                entityState={broadcastState}
                type={PlayerEntityTypes.Broadcast}
                colors={playerColors}
                startTimeOfCurrentTag={startTimeOfCurrentTag}
                withTimeChange={false}
                withDownload={false}
            />
        </PlayerWrapper>
    );
};

export default Player;
