import moment from 'moment';

import {
    HOUR_LENGTH,
    MINUTE_LENGTH,
    TIME_FORMAT,
} from '../constants/date-time';

export const getSeconds = (time: string) => {
    const timeItems = time.split(':');
    let timeSeconds = 0;
    if (timeItems.length == 2) {
        timeSeconds = (+timeItems[0]) * 60 + (+timeItems[1]);
    } else {
        timeSeconds = (+timeItems[0]) * 60 * 60 + (+timeItems[1]) * 60 + (+timeItems[2]);
    }
    return timeSeconds;
};

export const getSecondsFromString = (time: string) =>
    moment(time, TIME_FORMAT).diff(moment().startOf('day'), 'seconds');

export const getTimeFromSeconds = (value: number, format = TIME_FORMAT) => {
    const hours = Math.trunc(value / HOUR_LENGTH);
    const minutes = Math.trunc((value - hours * HOUR_LENGTH) / MINUTE_LENGTH);
    const seconds = value - hours * HOUR_LENGTH - minutes * MINUTE_LENGTH;

    return moment(`${hours}:${minutes}:${seconds}`, TIME_FORMAT).format(format);
};

export const getMMSSTimeFromSeconds = (time: number) => {
    const [hours, minutes, seconds] = getTimeFromSeconds(time).split(':');

    const preparedMinutes = (
        !Number.isNaN(parseInt(hours)) && !Number.isNaN(parseInt(minutes))
            ? parseInt(hours) * MINUTE_LENGTH + parseInt(minutes)
            : ''
    ).toString();

    return `${`${
        preparedMinutes.length === 1 ? '0' : ''
    }${preparedMinutes}`}:${seconds}`;
};
