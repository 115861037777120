import {memo} from 'react';

import Button from '../button';

import {Component} from './types';

import {ReactComponent as FullscreenExitIcon} from '@/components/ui-kit/icons/fullscreen-exit.svg';
import {ReactComponent as FullscreenIcon} from '@/components/ui-kit/icons/fullscreen.svg';

const FullscreenButton: Component = ({isFullscreen, onToggle, colors}) => (
    <Button
        colors={colors}
        label={isFullscreen ? 'Fullscreen Off' : 'Fullscreen'}
        onClick={onToggle}
    >
        {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
    </Button>
);

export default memo(FullscreenButton);
