import {Dispatch, ForwardedRef, SetStateAction} from 'react';
import {WatchObserver} from 'react-hook-form';

import {MAX_WIDGET_HEIGHT} from './consts';
import {MOMENTS_LENGTH} from './moment-card/consts';
import {IVideoFormValues, IVideoFormValuesKeys} from './types';

import {SECOND_LENGTH} from '@/lib/constants/date-time';
import {IMoment} from '@/lib/types/dto/moment';
import {NodeJSTimeoutType} from '@/lib/types/timeout';
import {scrollToElement} from '@/lib/utils/scroll';
import {setForwardedRef} from '@/lib/utils/set-ref';
import {isDefined} from '@/lib/utils/type-guards';

export type PlayMomentsProps = {
    ref: ForwardedRef<NodeJSTimeoutType>;
    moments: IMoment[];
    callback: (moment: IMoment) => void;
    currentIdx?: number;
    delay?: number;
    handleClearTimeout?: () => void;
};

/** Рекурсивная функция, которая воспроизводит текущий момент и затем по истечении его срока
 * воспроизводит следующий, если он есть
 */
export const playMoments = ({
    ref,
    moments,
    callback,
    currentIdx,
    delay = 0,
    handleClearTimeout,
}: PlayMomentsProps) => {
    /** Очищаем прошлый таймер */
    handleClearTimeout?.();

    if (isDefined(currentIdx)) {
        const current = moments[currentIdx];
        const currentDelay = MOMENTS_LENGTH[current?.momentName]?.length;

        callback(current);
        /** Проверяем, что в массиве moments есть следующий элемент */
        if ((delay || currentDelay) && currentIdx < moments.length - 1) {
            const timer = setTimeout(() => {
                /** Устанавливаем таймаут по длине текущего видео и по таймауту вызываем
                 * функцию на следующем элементе
                 */
                playMoments({
                    ref,
                    moments,
                    callback,
                    currentIdx: currentIdx + 1,
                    handleClearTimeout,
                });
            }, (delay || currentDelay) * SECOND_LENGTH);

            setForwardedRef(ref, timer);
        }

        /** Находим новый текущий элемент и проверяем его расположение,
         * если его y ниже высоты блока - делаем скролл к элементу
         */
        scrollToElement(`[dataId='${current?.recordId}']`, MAX_WIDGET_HEIGHT);
    }
};

export type WatchVideoFormCallbackProps = {
    setFormValues: Dispatch<SetStateAction<IVideoFormValues>>;
    moments: IMoment[];
};

/** Обработчик для watch формы */
export const watchVideoFormCallback =
    ({
        setFormValues,
        moments,
    }: WatchVideoFormCallbackProps): WatchObserver<IVideoFormValues> =>
    ({tags, quality, selectAll}, info) => {
        /** Создаем новый объект для состояния формы */
        const newFormValues: IVideoFormValues = {
            tags: tags?.filter(isDefined) ?? [],
            quality,
            selectAll: !!selectAll,
        };

        /** Если включен чекбокс Выбрать все и был кликнут тег,
         * выключаем чекбокс
         */
        if (selectAll && info.name === IVideoFormValuesKeys.Tags) {
            newFormValues.selectAll = false;
        }

        /** При смене состояния чекбокса обновляем выделение тегов */
        if (info.name === IVideoFormValuesKeys.SelectAll) {
            const newTagsState = selectAll
                ? moments.reduce<number[]>((result, moment) => {
                      if (isDefined(moment.id)) {
                          result.push(moment.id);
                      }
                      return result;
                  }, [])
                : [];
            newFormValues.tags = newTagsState;
        }

        /** Устанавливаем новые значения формы */
        setFormValues(newFormValues);
    };

