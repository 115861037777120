import {memo} from 'react';

import Button from '../button';

import {Component} from './types';

import {ReactComponent as SettingIcon} from '@/components/ui-kit/icons/gear.svg';

const Settings: Component = ({onToggle, colors}) => {
    return (
        <Button
            colors={colors}
            label="Настройки"
            onClick={onToggle}
        >
            <SettingIcon />
        </Button>
    );
};

export default memo(Settings);
