import {css} from '@emotion/css';

export const optionClassName = css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    background-color: #F2F3F5;
    color: black;

    &[aria-selected="true"] {
        background-color: rgba(0, 0, 0, 0.05);
    }
    
    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
`;