import {Tooltip} from 'antd';
import {memo} from 'react';

import Button from '../../../button';
import {getTooltipText, getUrlParamValue} from '../../utils';

import {Component} from './types';

import {
    EVideoParams,
} from '@/components/match-profile-page/components/custom-player/components/episode-time-control/types';
import {ReactComponent as TimeSkipIcon} from '@/components/ui-kit/icons/time-skip.svg';

const RewindButton: Component = ({
    extraTime,
    currentPlayingTagUrl,
    skipHandler,
}) => {
    const isSkipDisabled = () => {
        if (currentPlayingTagUrl && extraTime) {
            const startSecond = +(getUrlParamValue(EVideoParams.Start, currentPlayingTagUrl) || 0);
            return startSecond <= 10 || startSecond < extraTime.value;
        }
    };

    return (
        <Tooltip
            placement="top"
            title={getTooltipText('к концу эпизода', extraTime)}
            overlayInnerStyle={{
                fontWeight: '500',
                textAlign: 'center',
                maxWidth: 'min-content',
            }}
        >
            <Button
                onClick={() => skipHandler(extraTime?.value)}
                isDisabled={isSkipDisabled()}
            >
                <TimeSkipIcon />
            </Button>
        </Tooltip>
    );
};

export default memo(RewindButton);
