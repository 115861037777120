import {
    Box,
    Text,
    TextProps,
} from '@chakra-ui/react';
import styled, {StyledComponent} from '@emotion/styled';

export const LiveBadgeWrapper = styled(Box)`
    display: flex;
    align-items: center;
    padding: 0 4px;
    margin-top: 3px;
    background-color: gray;
    border-radius: 2px;
`;

export const LiveBadgeText: StyledComponent<TextProps> = styled(Text)`
    font-size: 12px;
    font-weight: 600;
`;
