import {ChangeEvent, FC, memo} from 'react';

import {Colors} from '../../types';
import Button from '../button';

import {volumeClassName} from './styles';

import {ReactComponent as VolumeHighIcon} from '@/components/ui-kit/icons/volume-high.svg';
import {ReactComponent as VolumeLowIcon} from '@/components/ui-kit/icons/volume-low.svg';
import {ReactComponent as VolumeMiddleIcon} from '@/components/ui-kit/icons/volume-middle.svg';
import {ReactComponent as VolumeMuteIcon} from '@/components/ui-kit/icons/volume-mute.svg';

interface VolumeProps {
    volume: number;
    onToggle: () => void;
    onSeek: (event: ChangeEvent<HTMLInputElement>) => void;
    colors: Colors;
}

const Volume: FC<VolumeProps> = ({volume, onToggle, onSeek, colors}) => {
    return (
        <div className={volumeClassName}>
            <div className="range">
                <div
                    className="range-background"
                    style={{backgroundColor: colors.volumeBackground}}
                />
                <div
                    className="range-current"
                    style={{
                        width: `${volume * 100}%`,
                        backgroundColor: colors.volumeCurrent,
                    }}
                >
                    <div
                        className="range-current-thumb"
                        style={{backgroundColor: colors.volumeThumb}}
                    />
                </div>
                <input
                    className="range-seek"
                    type="range"
                    value={volume}
                    max="1"
                    step="0.05"
                    onChange={onSeek}
                />
            </div>
            <Button
                colors={colors}
                onClick={onToggle}
            >
                {volume > 0.7 && <VolumeHighIcon/>}
                {volume <= 0.7 && volume > 0.3 && <VolumeMiddleIcon/>}
                {volume <= 0.3 && volume > 0 && <VolumeLowIcon/>}
                {volume === 0 && <VolumeMuteIcon/>}
            </Button>
        </div>
    );
};

export default memo(Volume);
