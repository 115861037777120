import {css} from '@emotion/css';

export const errorClassName = css`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 2rem;
    font-size: 1.5em;
    font-weight: 600;
    color: #DD1F26;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 100;

    svg {
        width: 5rem;
        height: 5rem;
        cursor: pointer;
    }
`;

