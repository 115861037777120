import {memo, useState} from 'react';

import TimeSelect from '../time-control/components/time-select';

import RewindButton from './components/rewind-button';
import SkipButton from './components/skip-button';
import {Controls} from './styles';
import {Component, TimeSelectItem} from './types';

import {Undefinable} from '@/lib/types';

const EpisodeTimeControl: Component = ({
    currentPlayingTagUrl,
    skipHandler,
    rewindHandler,
}) => {
    const [extraTime, setExtraTime] =
        useState<Undefinable<TimeSelectItem>>({value: 5, label: ' секунд'});

    return (
        <Controls>
            <RewindButton
                extraTime={extraTime}
                currentPlayingTagUrl={currentPlayingTagUrl}
                rewindHandler={rewindHandler}
            />
            <TimeSelect
                selectTimeHandler={setExtraTime}
            ></TimeSelect>
            <SkipButton
                extraTime={extraTime}
                currentPlayingTagUrl={currentPlayingTagUrl}
                skipHandler={skipHandler}
            />
        </Controls>
    );
};

export default memo(EpisodeTimeControl);
