import {Box} from '@chakra-ui/react';
import {Tabs, TabsProps} from 'antd';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import ReactPlayer from 'react-player';
import {useParams} from 'react-router-dom';

import withHammer from '../../hocs/with-hammer';

import MomentsBlock from './components/moments-block';
import PlayerBlock from './components/player-block';
import {SUCCESS_MATCH_PROCESSING_STATUS} from './consts';
import {ContentWrapper, tabsClassName} from './styles';
import {Component} from './types';
import {makeVideoTabsItems} from './utils';

import Loader from '@/components/loader';
import {MatchHalf} from '@/lib/constants/match-statistics';
import {BroadcastMoment} from '@/lib/enums/broadcast-moment';
import {useBroadcast} from '@/lib/queries/use-broadcast';
import {useMatch} from '@/lib/queries/use-match';
import {useMatchProcessingStatus} from '@/lib/queries/use-match-processing-status';
import {Undefinable} from '@/lib/types';
import {RouteType} from '@/lib/types/route-type';
import {NodeJSTimeoutType} from '@/lib/types/timeout';
import {getSecondsFromString} from '@/lib/utils/date-time';
import {parseNumber} from '@/lib/utils/parse-number';
import {isDefined} from '@/lib/utils/type-guards';

const MatchProfilePage: Component = () => {
    const {id} = useParams<RouteType>();
    const [currentBroadcastIdx, setCurrentBroadcastIdx] = useState(0);

    const {data: match, isLoading: isMatchLoading} = useMatch(Number(id));
    const {data: broadcast, isLoading: isBroadcastLoading} =
        useBroadcast(match?.broadcasts[currentBroadcastIdx]?.broadcastId);
    const {data: matchProcessingStatus, isLoading} = useMatchProcessingStatus(id);
    const isMatchProcessingDone = useMemo(() => {
        return matchProcessingStatus === SUCCESS_MATCH_PROCESSING_STATUS;
    }, [matchProcessingStatus]);

    const [broadcastsTimeCache, setBroadcastCacheTime] =
        useState<Record<number, number>>({});
    const [startTimeOfCurrentTag, setStartTimeOfCurrentTag] =
        useState<number>();

    const [playbackState, setPlaybackState] = useState(false);
    const [currentTime, setCurrentTime] = useState('00:00');

    const [momentsFilters, setMomentsFilters] =
        useState<Record<string, BroadcastMoment[]>>({});
    const [momentsPlayers, setMomentsPlayers] =
        useState<number[]>([]);

    const timerRef = useRef<NodeJSTimeoutType>(undefined);
    const ref = useRef<ReactPlayer>(null);

    useEffect(() => {
        if (ref.current && broadcastsTimeCache[currentBroadcastIdx]) {
            /** При переходе к другой трансляции на странице проверяем, есть ли
             * закэшированное время и переходим к нему, если есть
             */
            ref.current.seekTo(broadcastsTimeCache[currentBroadcastIdx]);
        }
    }, [broadcast?.id]);

    const handleTimeLabelClick = useCallback(
        (timeStartSeconds: number, half?: MatchHalf) => {
            let offset: Undefinable<string> = undefined;
            if (half === MatchHalf.First) {
                offset = match?.broadcasts[currentBroadcastIdx].startFirstHalf;
            }

            if (half === MatchHalf.Second) {
                offset = match?.broadcasts[currentBroadcastIdx].startSecondHalf;
            }

            const timeStart = offset
                ? timeStartSeconds + getSecondsFromString(offset)
                : timeStartSeconds;

            setStartTimeOfCurrentTag(timeStart);
            ref.current?.seekTo(timeStart);
        },
        [currentBroadcastIdx, match?.broadcasts, playbackState],
    );

    const handleClearTimeout = () => {
        clearTimeout(timerRef.current);
    };

    const handleChangeBroadcast = (broadcastKey: string) => {
        const nextBroadcastId = parseNumber(broadcastKey);

        if (!isDefined(nextBroadcastId)) {
            return;
        }

        const currentTime = ref.current?.getCurrentTime();

        if (currentTime) {
            /** кэшируем время, на котором остановилась текущая трансляция,
             * при переходе на другую
             */
            setBroadcastCacheTime((prevState) => {
                return {
                    ...prevState,
                    [currentBroadcastIdx]: currentTime,
                };
            });
        }

        setCurrentBroadcastIdx(nextBroadcastId);
    };

    useEffect(() => {
        return handleClearTimeout;
    }, []);

    const broadcastsSelector: TabsProps['items'] = useMemo(() => {
        return makeVideoTabsItems(match?.broadcasts.length);
    }, [match?.broadcasts.length]);

    if (isLoading || isMatchLoading || isBroadcastLoading) {
        return <Loader />;
    }

    return (
        <ContentWrapper>
            <Box>
                <PlayerBlock
                    isMatchProcessingSuccess={isMatchProcessingDone}
                    setMomentsFilters={setMomentsFilters}
                    momentsFilters={momentsFilters}
                    currentBroadcastIdx={currentBroadcastIdx}
                    setMomentsPlayers={setMomentsPlayers}
                    momentsPlayers={momentsPlayers}
                    startTimeOfCurrentTag={startTimeOfCurrentTag}
                    playbackState={playbackState}
                    setPlaybackState={setPlaybackState}
                    currentTime={currentTime}
                    setCurrentTime={setCurrentTime}
                />
            </Box>
            <Box>
                <Tabs
                    className={tabsClassName}
                    onChange={handleChangeBroadcast}
                    items={broadcastsSelector}
                />
                <MomentsBlock
                    playbackState={playbackState}
                    currentTime={currentTime}
                    broadcast={broadcast}
                    filters={momentsFilters}
                    playersIds={momentsPlayers}
                    handleTimeLabelClick={handleTimeLabelClick}
                    handleClearTimeout={handleClearTimeout}
                    isMatchProcessingSuccess={isMatchProcessingDone}
                    ref={timerRef}
                />
            </Box>
        </ContentWrapper>
    );
};

export default withHammer(MatchProfilePage);
