import {keyframes} from '@chakra-ui/react';

import {baseTheme} from '@/components/ui-kit/theme/default/base';

const animationKeyframes = keyframes`
    from { opacity: 1; }
    to { opacity: 0; }
`;
export const animation = `${animationKeyframes} 1s linear 1`;

export const iFrameAllow =
    'accelerometer;autoplay;clipboard-write;encrypted-media;gyroscope;picture-in-picture;web-share';

export const playerColors = {
    text: baseTheme.colors.gray[300],
    volumeBackground: baseTheme.colors.gray[400],
    volumeCurrent: baseTheme.colors.turquoise,
    volumeThumb: baseTheme.colors.gray[300],
    progressBackground: baseTheme.colors.gray[300],
    progressCurrent: baseTheme.colors.turquoise,
    progressBuffer: baseTheme.colors.gray[400],
    progressThumb: baseTheme.colors.gray[300],
};
