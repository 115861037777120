import {memo} from 'react';

import {LiveBadgeText, LiveBadgeWrapper} from './styles';
import {Component} from './types';

export const LiveBadge: Component = ({isLive, ...props}) => {
    return (
        <LiveBadgeWrapper style={{backgroundColor: `${isLive ? '#DD1F26' : '#808080'}`}} {...props}>
            <LiveBadgeText>В ЭФИРЕ</LiveBadgeText>
        </LiveBadgeWrapper>
    );
};

export default memo(LiveBadge);
