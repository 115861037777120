import {memo} from 'react';
import {CSSTransition} from 'react-transition-group';

import {loaderClassName} from './styles';
import {Component} from './types';

const Loader: Component = ({on}) => (
    <CSSTransition
        in={on}
        classNames={loaderClassName}
        timeout={300}
        mountOnEnter
        unmountOnExit
    >
        <div className={loaderClassName}>
            <div />
        </div>
    </CSSTransition>
);

export default memo(Loader);
