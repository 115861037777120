import {memo} from 'react';

import Select from '../../../../../controls/select';

import {OPTIONS} from './consts';
import {Component} from './types';

const TimeSelect: Component = ({
    extraTime,
    selectTimeHandler,
}) => {

    return (
        <Select
            options={OPTIONS}
            selected={extraTime}
            onChange={selectTimeHandler}
        />
    );
};

export default memo(TimeSelect);
