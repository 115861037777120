import {memo} from 'react';

import MomentCard from '../moment-card';

import {Wrapper, Scroll, scrollClassName} from './styles';
import {Component} from './types';

import Loader from '@/components/loader';
import {isDefined} from '@/lib/utils/type-guards';

const MomentsContent: Component = ({
    moments,
    isLoading,
    handleMomentClick,
    currentPlayingMoment,
    setCurrentPlayingMoment,
    hasNextPage,
    fetchNextPage,
}) => {
    if (isLoading) {
        return <Loader />;
    }

    return (
        <Wrapper>
            <Scroll
                next={fetchNextPage}
                hasMore={!!hasNextPage}
                loader={<Loader />}
                dataLength={moments?.length}
                className={scrollClassName}
            >
                {moments?.map((moment) => {
                    const handlePlay = (value: boolean) => {
                        setCurrentPlayingMoment(
                            isDefined(value) ? moment.recordId : undefined,
                        );
                    };
                    return (
                        <MomentCard
                            key={moment.recordId}
                            moment={moment}
                            handleTimeLabelPlayButtonClick={handleMomentClick}
                            isPlaying={currentPlayingMoment == moment.recordId}
                            setPlaying={handlePlay}
                        />
                    );
                })}
            </Scroll>
        </Wrapper>
    );
};

export default memo(MomentsContent);
