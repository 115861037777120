import {css} from '@emotion/css';

export const progressClassName = css`
    position: relative;
    width: 100%;
    height: 100%;
    
    .range {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
    }
    .range-background,
    .range-buffer,
    .range-current,
    .range-seek {
        position: absolute;
        width: 100%;
        height: 4px;
        border-radius: 50px;
    }
    .range-current {
        position: relative;
        display: flex;
        align-items: center;
        padding-left: 14px;
    }
    .range-current-thumb {
        position: absolute;
        right: 0;
        width: 15px;
        height: 15px;
        border-radius: 50px;
    }
    .range-seek {
        height: 100%;
        cursor: pointer;
        opacity: 0;
        padding-left: 14px;
    }
    
    .tooltip {
        position: absolute;
        bottom: 20px;
        padding: 0.5rem 0.75rem;
        background-color: rgba(0, 0, 0, 0.8);
        border-radius: 5px;
        font-weight: 700;
        pointer-events: none;
        opacity: 0;
        transform: translateX(-50%);
        transition: opacity 200ms ease-out;
    }
    &:hover .tooltip {
        opacity: 1;
    }
`;

