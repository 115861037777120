import {Option} from '@/components/match-profile-page/components/controls/select/types';

export const getUrlParamValue = (param: string, url: string) => {
    const re = new RegExp(param + '=([0-9]+)');
    const paramValueStr = url.match(re);
    if (paramValueStr) {
        return paramValueStr.toString().split(',')[1];
    }
};

export const getTooltipText = (text: string, time?: Option) => {
    return `Добавить ${time?.value || '0'} ${time?.label} ${text}`;
};
