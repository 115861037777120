import {memo} from 'react';

import {timeClassName} from './styles';
import {Component} from './types';

const Time: Component = ({time}) => (
    <time className={timeClassName} dateTime={time}>
        {time}
    </time>
);

export default memo(Time);
