import {useState, useEffect, useRef, MouseEventHandler} from 'react';

import SelectOption from './components/option';
import {
    placeholderClassName,
    selectClassName,
    selectWrapperClassName,
} from './styles';
import {Option, Component} from './types';

const Select: Component = ({
    options,
    placeholder,
    status,
    selected,
    onChange,
    onClose,
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const rootRef = useRef<HTMLDivElement>(null);
    const placeholderRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClick = (event: MouseEvent) => {
            const {target} = event;
            if (target instanceof Node && !rootRef.current?.contains(target)) {
                isOpen && onClose?.();
                setIsOpen(false);
            }
        };

        window.addEventListener('click', handleClick);

        return () => {
            window.removeEventListener('click', handleClick);
        };
    }, [onClose]);

    useEffect(() => {
        const placeholderEl = placeholderRef.current;
        if (!placeholderEl) {
            return;
        }

        const handleEnterKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Enter') {
                setIsOpen((prev) => !prev);
            }
        };
        placeholderEl.addEventListener('keydown', handleEnterKeyDown);

        return () => {
            placeholderEl.removeEventListener('keydown', handleEnterKeyDown);
        };
    }, []);

    const handleOptionClick = (value: Option['value']) => {
        setIsOpen(false);
        onChange?.({value, label: ''});
    };
    const handlePlaceHolderClick: MouseEventHandler<HTMLDivElement> = () => {
        setIsOpen((prev) => !prev);
    };

    return (
        <div
            className={selectWrapperClassName}
            ref={rootRef}
            data-is-active={isOpen}
            data-testid="selectWrapper"
        >
            <div
                className={placeholderClassName}
                data-status={status}
                data-selected={!!selected?.value}
                onClick={handlePlaceHolderClick}
                role="button"
                tabIndex={0}
                ref={placeholderRef}
            >
                {selected?.value || placeholder}
            </div>
            {isOpen && (
                <ul className={selectClassName} data-testid="selectDropdown">
                    {options.map((option) => (
                        <SelectOption
                            key={option.value}
                            option={option}
                            onClick={handleOptionClick}
                        />
                    ))}
                </ul>
            )}
        </div>
    );
};

export default Select;
